import { secondsToTime, ordinal_suffix_of } from "../server/components/utils"
import moment from 'moment'

let formAthleteList = document.getElementById('form-athlete-list')
if(formAthleteList)
{
	let selects = Array.from(formAthleteList.getElementsByTagName("select"))
	selects.forEach( function(element, index) {
		element.addEventListener("change", (e) => {
			let btn = formAthleteList.querySelector("button[type=submit]")
			if(btn) btn.click()
		})
	});
}

let athleteBio = document.getElementById('athlete-bio')
let athleteBioMore = document.getElementById('athlete-bio-more')
if(athleteBio && athleteBioMore)
{
	athleteBioMore.addEventListener("click", (e) => {
		if(athleteBio.classList.contains("expand"))
		{
			athleteBio.classList.remove("expand")
			athleteBioMore.innerHTML = "Show more"
		}
		else
		{
			athleteBio.classList.add("expand")
			athleteBioMore.innerHTML = "Show less"
		}
	})
}

Array.from(document.getElementsByClassName("onshare")).forEach(function(b) {
	b.addEventListener('click', function(e) {
		let athleteid = b.dataset.athleteid
		let athletename = b.dataset.athletename
		let athletelink = b.dataset.athletelink

		fetch("/athlete/" + athleteid + "/image", {
		})
		.then(function(response) { 
			if(response.ok) return response.blob(); return Promise.reject(response); 
		})
		.then(function(blob) {
			let file = new File([blob], athletelink + "-profile.png", {type: 'image/png'});
			let filesArray = [file];
			if(navigator.share)
			{
				navigator.share({
					title: athletename + ' - PTO World Rankings',
					text: athletename + ' - PTO World Rankings',
					url: location.href,
					files: filesArray, 
				}).then(() => {
					console.log('Thanks for sharing!');
				}).catch((error) => {
					var link = document.createElement("a");
					link.download = athletelink + "-profile.png";
					link.href = URL.createObjectURL(blob);
					link.target = '_blank';
					link.click();
				});
			}
			else
			{
				var link = document.createElement("a");
				link.download = athletelink + "-profile.png";
				link.href = URL.createObjectURL(blob);
				link.target = '_blank';
				link.click();
			}
		})
	});
});

function loadImage(src) 
{
	return new Promise((resolve, reject) => {
		let img = new Image()
		img.onload = () => resolve(img)
		img.onerror = reject
		img.src = src
	})
}

function fillTextCenter(ctx, text, x, y, center_y = false)
{
	let m = ctx.measureText(text)
	ctx.fillText(text, x - m.width * 0.5, y + (center_y ? m.actualBoundingBoxAscent * 0.5 : 0))
	return m
}

function drawRing(ctx, name, percent, color, x, y)
{
	ctx.fillStyle = color

	
	if (percent != "-") {
		ctx.font = '90px BlackIt'
		ctx.fillStyle = color
		ctx.font = '45px BlackIt'
		let m = ctx.measureText(percent)
		fillTextCenter(ctx, "#",  x - m.width - 4, y + 70)
	}

	ctx.fillStyle = 'white'
	ctx.font = '90px BlackIt'
	fillTextCenter(ctx, percent, x + 0, y + 100)

	ctx.lineWidth = 14
	ctx.strokeStyle = "#444"
	ctx.beginPath()
	ctx.stroke()

	ctx.lineCap = "round"
	ctx.strokeStyle = color
	ctx.beginPath()
	ctx.stroke()

	ctx.font = '30px Regular'
	fillTextCenter(ctx, name, x + 0, y + 160)
}

let background_image = null
let background_down_image = null
let rankings_image = null

let data_j = null
let flag = null
let team_image = null
let image = null

async function init(id, done)
{
	let black_font = new FontFace('Black', 'url(/assets/fonts/Metropolis/Metropolis-Black.otf)')
	await black_font.load()
	document.fonts.add(black_font)

	let blackit_font = new FontFace('BlackIt', 'url(/assets/fonts/Metropolis/Metropolis-BlackItalic.otf)')
	await blackit_font.load()
	document.fonts.add(blackit_font)

	let regular_font = new FontFace('Regular', 'url(/assets/fonts/Metropolis/Metropolis-Regular.otf)')
	await regular_font.load()
	document.fonts.add(regular_font)

	let bold_font = new FontFace('Bold', 'url(/assets/fonts/Metropolis/Metropolis-Bold.otf)')
	await bold_font.load()
	document.fonts.add(bold_font)

	let lato_bold_font = new FontFace('Lato Bold', 'url(/assets/fonts/Lato/Lato-Bold.ttf)')
	await lato_bold_font.load()
	document.fonts.add(lato_bold_font)

	background_image = await loadImage('/assets/background_texture_2.png')
	background_down_image = await loadImage('/assets/background_texture_down.png')
	rankings_image = await loadImage('/assets/rankings-white-2lines.png')

	let data = await fetch("/athlete/" + id + "/data")
	data_j = await data.json()

	let info = data_j.info
	let team_name = data_j.team_name
	let pic = JSON.parse(info.pic)

	flag = await loadImage("/proxy?url=https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.1.0/flags/4x3/" + info.country.toLowerCase() + ".svg")
	team_image = await loadImage('/assets/team_' + team_name + '_cc.png')

	image = pic ? await loadImage("/proxy?url=" + pic.img.src) : null

	done()
}

async function updateCanvas(canvas, id, options)
{
	let info = data_j.info
	let races = data_j.races
	let rankings = data_j.rankings
	let rank = rankings.length ? rankings[0].overall_rank : "-"
	canvas.width = 1080
	canvas.height = options.type == 1 ? 1920 : 1080
	const ctx = canvas.getContext('2d')


	ctx.clearRect(0, 0, canvas.width, canvas.height)
	ctx.beginPath()
	ctx.rect(0, 0, canvas.width, canvas.height)
	ctx.fillStyle = "#282828"
	ctx.fill()

	let safe_top = 120

	if(options.type == 1)
	{
		if(background_image) ctx.drawImage(background_image, 0, 0, 1080, 600 + safe_top)
		if(background_down_image) 
		{
			ctx.save()
				ctx.drawImage(background_down_image, 0, 600 + safe_top, 1080, (options.type == 1 ? 1920 : 1080) - 600 - safe_top)
			ctx.restore()
		}

		ctx.save()
			ctx.translate(0, safe_top);
			if(image) ctx.drawImage(image, (1080-600), 0, 600, 600)

			let first = info.first.toUpperCase()
			let last = info.last.toUpperCase()
			ctx.fillStyle = 'white'
			ctx.font = '70px Regular'
			let m_first = ctx.measureText(first)
			ctx.font = '70px Black'
			let m_last = ctx.measureText(last)
			let font_size = 70
			let max_width = 520 
			if(m_first.width > max_width || m_last.width > max_width)
			{
				font_size = Math.floor(font_size * max_width / Math.max(m_first.width, m_last.width))
			}
			ctx.font = font_size + 'px Regular'
			ctx.fillText(info.first.toUpperCase(), 60, 100)
			ctx.font = parseInt(1*font_size) + 'px Black'
			ctx.fillText(info.last.toUpperCase(), 60, 100 + font_size)

			ctx.save()
				if(rank != "-")
				{
					ctx.font = '90px BlackIt'
					let m = ctx.measureText(rank)
					ctx.fillStyle = '#D10B0C'
					ctx.font = '45px BlackIt'
					ctx.fillText("#", 350 + 0, 300 - 30)
				}

				ctx.fillStyle = 'white'
				ctx.font = '90px BlackIt'
				ctx.fillText(rank, 350 + 20, 300)
			ctx.restore()
			ctx.drawImage(rankings_image, 60, 230, 250, 250 * 111 / 348)


			ctx.save()
				ctx.fillStyle = 'white'
				ctx.translate(140, 400);
				ctx.font = '30px Regular'

				fillTextCenter(ctx, "COUNTRY", 0, 100)
				fillTextCenter(ctx, "AGE", 160, 100)

				ctx.fillStyle = 'white'
				ctx.font = '60px Black'

				let age = info.dob ? moment().diff(moment.utc(info.dob), 'years') : "-"

				ctx.drawImage(flag, 0 - 2*20, 0, 4*20, 3*20)
				fillTextCenter(ctx, age, 160, 50)
			
			ctx.restore()
		ctx.restore()


		if(races.length)
		{
			let race = null
			for(let _r of races)
			{
				if(_r.status == "Finish")
				{
					race = _r
					break
				}
			}
			if(race)
			{
				ctx.save()
					ctx.translate(0, 680 + safe_top);
					ctx.fillStyle = 'white'
					ctx.font = '80px Black'
					fillTextCenter(ctx, race.serie, 1080 * 0.5, 40)

					ctx.fillStyle = 'white'
					ctx.font = '40px Regular'
					fillTextCenter(ctx, moment.utc(race.date).format("DD MMM YYYY"), 1080 * 0.5, 100)

					let y = 250
					for(let discipline of ["overall", "swim","bike","run"])
					{
						let rank = race[discipline + "_rank"]
						ctx.font = '50px Regular'
						let d = ordinal_suffix_of(rank) + " " + discipline.toUpperCase()
						let m = ctx.measureText(d)
						ctx.fillText(d, 1080 * 0.5 - m.width * 0.5, y + 70)
						ctx.font = '80px Black'
						d = secondsToTime(race[discipline])
						m = ctx.measureText(d)
						ctx.fillText(d, 1080 * 0.5 - m.width * 0.5, y)
						y += 200
					}

				ctx.restore()
			}
		}
	}
	else
	{
		if(background_image) ctx.drawImage(background_image, 0, 0, 1080, 600)
		if(background_down_image) 
		{
			ctx.save()
				ctx.drawImage(background_down_image, 0, 600, 1080, (options.type == 1 ? 1920 : 1080) - 600)
			ctx.restore()
		}

		if(image) ctx.drawImage(image, (1080-600), 0, 600, 600)

		let first = info.first.toUpperCase()
		let last = info.last.toUpperCase()
		ctx.fillStyle = 'white'
		ctx.font = '70px Regular'
		let m_first = ctx.measureText(first)
		ctx.font = '70px Black'
		let m_last = ctx.measureText(last)
		let font_size = 70
		let max_width = 520 
		if(m_first.width > max_width || m_last.width > max_width)
		{
			font_size = Math.floor(font_size * max_width / Math.max(m_first.width, m_last.width))
		}
		ctx.font = font_size + 'px Regular'
		ctx.fillText(info.first.toUpperCase(), 60, 100)
		ctx.font = parseInt(1*font_size) + 'px Black'
		ctx.fillText(info.last.toUpperCase(), 60, 100 + font_size)

		ctx.save()
			if(rank != "-")
			{
				ctx.font = '90px BlackIt'
				let m = ctx.measureText(rank)
				ctx.fillStyle = '#D10B0C'
				ctx.font = '45px BlackIt'
				ctx.fillText("#", 350 + 0, 300 - 30)
			}

			ctx.fillStyle = 'white'
			ctx.font = '90px BlackIt'
			ctx.fillText(rank, 350 + 20, 300)
		ctx.restore()

		ctx.drawImage(rankings_image, 60, 230, 250, 250 * 111 / 348)
		ctx.drawImage(team_image, 60, 360, 4*50, 4*50)
		const national_rank = shareCanvas.dataset.nationalRank || "-"
		ctx.save()
			ctx.translate(0, options.type == 1 ? -220 : 0);
			if(options.type != 1)
			{
				ctx.save()
					ctx.translate(1080 * 0.5, 660);
					drawRing(ctx, "SWIM", rankings[0].swim_rank, "#456AE5", -300, 0)
					drawRing(ctx, "BIKE", rankings[0].bike_rank, "#009D5B", -100, 0)
					drawRing(ctx, "RUN", rankings[0].run_rank, "#D10B0C", 100, 0)
					drawRing(ctx, "NATIONAL", national_rank, "#D10B0C", 300, 0)
				ctx.restore()
			}

			ctx.save()
				ctx.fillStyle = 'white'
				ctx.translate(370, 870);
				ctx.font = '30px Regular'

				fillTextCenter(ctx, "COUNTRY", -130, 100)
				fillTextCenter(ctx, "WEIGHT", 50, 100)
				fillTextCenter(ctx, "HEIGHT", 225, 100)
				fillTextCenter(ctx, "AGE", 375, 100)
				fillTextCenter(ctx, "BORN", 500, 100)

				ctx.fillStyle = 'white'
				ctx.font = '40px Black'

				let age = info.dob ? moment().diff(moment.utc(info.dob), 'years') : "-"
				let dob = info.dob ? moment.utc(info.dob).format("YYYY") : "-"
				let height = info.height ? (info.height / 100).toFixed(2) + " m" : "-"
				let weight = info.weight ? (info.weight) + " kg" : "-"

				ctx.drawImage(flag, -130 - 2*20, 0, 4*20, 3*20)
				fillTextCenter(ctx, weight, 50, 50)
				fillTextCenter(ctx, height, 225, 50)
				fillTextCenter(ctx, age, 375, 50)
				fillTextCenter(ctx, dob, 500, 50)
			
			ctx.restore()
		ctx.restore()
	}

	ctx.fillStyle = '#888'
	ctx.font = options.type == 1 ? '45px Black' : '35px Black'
	fillTextCenter(ctx, "stats.protriathletes.org", 1080 * 0.5, canvas.height - 40)

	let shareImage = document.getElementById('share-image')
	if(shareImage)
	{
		shareImage.src = canvas.toDataURL('image/jpeg', 0.95);
	}
}

let shareCanvas = document.getElementById('share-canvas')

let formShare = document.getElementById('form-share')
let downloadShare = document.getElementById('download-share')

function readFormAndUpdate()
{
	let options = {}
	for(let e of formShare.elements) 
	{
		if(e.type == "radio" && !e.checked) continue
		if(e.name) options[e.name] = e.value
	}
	updateCanvas(shareCanvas, shareCanvas.dataset.id, options)
}

if(shareCanvas && formShare && downloadShare)
{
	init(shareCanvas.dataset.id, () => {

		document.getElementById('loading-share').remove()

		readFormAndUpdate()		

		let selects = Array.from(formShare.getElementsByTagName("select"))
		selects.forEach( function(element, index) {
			element.addEventListener("change", (e) => {
				readFormAndUpdate()
			})
		});

		let radios = formShare.querySelectorAll('input[type=radio]')
		radios.forEach( function(element, index) {
			element.addEventListener("change", (e) => {
				readFormAndUpdate()
			})
		});

		downloadShare.addEventListener("click", (e) => {
			var link = document.createElement("a");

			link.download = data_j.info.link + "-share.jpg";
			let shareImage = document.getElementById('share-image')
			link.href = shareImage.src
			link.click();
		})
	})
}

let shareShare = document.getElementById('share-share')
if(shareShare)
{
	if(!navigator.share) shareShare.remove()
	else
	{
		shareShare.addEventListener("click", (e) => {
			shareCanvas.toBlob(function(blob) {
				let file = new File([blob], data_j.info.link + "-share.jpg", {type: 'image/jpeg'});
				let filesArray = [file];
				if(navigator.share)
				{
					navigator.share({
						title: data_j.info.name + ' - PTO World Rankings',
						text: data_j.info.name + ' - PTO World Rankings',
						url: location.href.replace("/share", ""),
						files: filesArray, 
					}).then(() => {
						console.log('Thanks for sharing!');
					}).catch((error) => {
						downloadShare.click()
					});
				}

			}, "image/jpeg", 0.95);

		})		
	}
}

let expandDetails = document.querySelector(".athlete-info .expand-details")
if (expandDetails) {
	expandDetails.addEventListener("click", function(e) {
		let drawElement = document.querySelector('.athlete-info .t100-contracts__draw')
		const isOpen = drawElement.classList.contains('open')

		if(isOpen) {
			drawElement.classList.remove('open')
		} else {
			drawElement.classList.add('open')
		}
	})
}

let pointsDropdownsToggles = document.querySelectorAll('.points-dropdown-toggle')
pointsDropdownsToggles.forEach(function(toggle) {
	toggle.addEventListener('click', function(e) {
		const dropdownId = toggle.getAttribute('data-dropdown')
		let dropdown = document.getElementById(dropdownId)
		const isOpen = dropdown.classList.contains('open')
		const icon = toggle.querySelector('i')

		if (isOpen) {
			dropdown.classList.remove('open')
			icon.classList.remove('fa-caret-square-up')
			icon.classList.add('fa-caret-square-down')
		} else {
			dropdown.classList.add('open')
			icon.classList.remove('fa-caret-square-down')
			icon.classList.add('fa-caret-square-up')
		}
	})
})
