let raceYears = document.getElementById('race-years')
if(raceYears)
{
	raceYears.addEventListener("change", (e) => {
		location = e.target.value
	})
}

let form = document.querySelector(".filters-wrapper form")
if(form) {
	const selects = form.querySelectorAll("select:not(.sortable-select-control)")
	selects.forEach( function(element, index) {
		element.addEventListener("change", (e) => {
			form.submit()
		})
	});
}

let upcomingRacesShowMore = document.querySelector('.upcoming-races .js-toggle-showmore')
if(upcomingRacesShowMore)
{
	upcomingRacesShowMore.addEventListener("click", (e) => {
		e.preventDefault()
		let hideableUpcomingRaces = document.querySelectorAll('.upcoming-races .upcoming-race.hideable')

		if (!e.target.classList.contains('active')) {
			e.target.classList.add('active')
			hideableUpcomingRaces.forEach(race => {
				race.classList.remove('d-none');
			});
			upcomingRacesShowMore.innerHTML = 'Show fewer upcoming races';
		}
		else {
			e.target.classList.remove('active')
			hideableUpcomingRaces.forEach(race => {
				race.classList.add('d-none');
			});
			upcomingRacesShowMore.innerHTML = 'Show more upcoming races';
		}
	})
}
